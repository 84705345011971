// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-packages-js": () => import("./../../../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-spa-js": () => import("./../../../src/pages/spa.js" /* webpackChunkName: "component---src-pages-spa-js" */),
  "component---src-pages-weddings-js": () => import("./../../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */),
  "component---src-templates-builder-page-js": () => import("./../../../src/templates/builder-page.js" /* webpackChunkName: "component---src-templates-builder-page-js" */),
  "component---src-templates-room-page-js": () => import("./../../../src/templates/room-page.js" /* webpackChunkName: "component---src-templates-room-page-js" */)
}

